import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Matchups from "./Matchups";

// core components

function WeeklyRecap() {
  return (
    <>
      <div className="section section-weekly-recap">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
                  <h1 className="title">WEEK 5 <span className="team-name-tag" style={{color: "red"}}>RECAP</span></h1>
                  {/* <h5 className="description">
                  ITS HERE!!!!
                  </h5> */}
            </Col>            
              {/* <div className="hero-images-container">
                <img`
                  alt="..."
                  src={require("assets/img/hero-image-1.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/devin-white.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div> */}              
          </Row>
          <Row>
            <Matchups />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WeeklyRecap;
