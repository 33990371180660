import React from "react";
import Matchup from "./Matchup";
// reactstrap components
import{
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container, 
  Row
} from "reactstrap";

// core components

function Matchups() {
    // const matchups = [
    //     {"winner": "Double Trouble", "loser": "Bama BlackOut", 
    //         "text": "This one was a high scoring affair, with the two highest scoring teams this week squaring off. It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!"},

    //     {"winner": "! Zo", "loser": "Dandy MEN", 
    //         "text": "Deshaun Watson and Will Fuller set the tone early on Thanksgiving day, and <span className='bold team-name-tag'>Dandy MEN</span> never let up, handing <span className='bold team-name-tag'>! Zo</span> his third straight loss. This loss all but eliminates ! Zo from the playoffs. Dandy can clinch his first ever playoff birth with a win next week against Bama. I'm not gonna lie, I'm rooting for the boy Dandy, even though it's me (Bama) he's playing next week!!!!!"},

    //     {"winner": "The Goonz", "loser": "THE Big D", 
    //         "text": "Dominant. <span className='bold team-name-tag'>The Goonz</span> making it look easy once again to get to the magical <i>SEVENTH</i> win this week putting an exclamation point on an impressive season knocking off <span className='bold team-name-tag'>The Big D</span>. D has really struggled this year following an impressive draft, but has vowed to have studied The League like a playbook and knows what all he did wrong in 2020. Their won't be no easy outs in 2021!!!!!"},


    // ];
  return (
    <>
      <div className="section section-weekly-recap">
        <Container>
            <br />
            <br />
            <br />

            {/* {matchups.map(function(matchup, idx){
                 return (<Matchup key={idx} winner={matchup.winner} loser={matchup.loser} text={matchup.text} />)
             })} */}
        {/* <Matchup winner="Double Trouble" loser="Bama BlackOut" 
        text="This one was a high scoring affair, with the two highest scoring teams this week squaring off. 
        It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping 
        the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the 
        league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!" /> */}
        <Row>
        <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>  
                <span className="bold team-name-tag">King Jaffe Joffer</span> reigned supreme this week, 
                defeating <span className="bold team-name-tag">Sport Of Kings</span> in <span className="bold green">commanding fashion</span>. Fueled by 
                a resurgent <span className="italic bold">Joe Burrow</span>, who nearly outscored <span className="italic bold">Kings’</span> entire 
                offense on his own, <span className="italic bold">Joffer’s</span> squad was <span className="bold blue">unstoppable</span>. Meanwhile, <span className="italic bold">SOK</span> continues 
                to <span className="bold red">struggle finding his rhythm</span> this season, with high expectations from <span className="italic bold">Marvin Harrison Jr. and George Pickens</span> falling 
                short so far. Despite the preseason hype, <span className="italic bold">SOK</span> hasn’t been able to get out of the blocks, while <span className="italic bold">Joffer</span> is slowly 
                starting to <span className="bold orange">turn his season around</span>. The King’s crown remains firmly in place after this dominant performance.!!!!!
                </CardText>          
                </CardBody>
            </Card>   
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">King Jaffe Joffer</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">Sport Of Kings</CardTitle>          
                </CardBody>
            </Card>            
          </Row>
            <br/>
            <br/>
          <Row>
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">Double Trouble</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">Zeke's Supreme Team</CardTitle>          
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>       
                <span className="bold team-name-tag">Double Trouble</span> flexed his <span className="bold green">fantasy muscle</span> in 
                a <span className="bold red">dominant</span> win over <span className="bold team-name-tag">Zeke's Supreme Team</span>, solidifying 
                his status as an <span className="bold blue">unstoppable force</span> this season. With 
                a <span className="bold blue">jaw-dropping</span> <span className="bold">600+ points</span> on the board, <span className="italic bold">Double Trouble</span> looks 
                poised for a <span className="bold orange">deep championship run</span>. <span className="bold red">Could this finally be his year</span>? Meanwhile, <span className="italic bold">Zeke’s Supreme Team</span> struggled 
                to generate any offensive momentum, but thanks to a <span className="bold blue">heroic effort</span> from his DBs, they managed to 
                keep the game somewhat competitive. In the end, though, it wasn’t enough 
                to slow down the <span className="bold blue">freight train</span> that is <span className="italic bold">Double Trouble</span>, leaving fantasy managers wondering 
                if anyone can stand in his way.!!!!!

                </CardText>          
                </CardBody>
            </Card> 
          </Row>
            <br/>
            <br/>
          <Row>            
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>   
                In a <span className="bold red">heartbreaker of a matchup</span>, <span className="bold team-name-tag">The Big D</span> edged out <span className="bold team-name-tag">! Zo</span> in 
                a <span className="bold green">high scoring showdown</span>. Sometimes the fantasy gods just 
                have it out for you. This week Zo put up <span className="bold orange">591 points</span> and still lost. <span className="bold">It sucks</span>. Zo couldn’t overcome 
                the <span className="bold blue">relentless force</span> of the Big D, who cracked <span className="bold">600+ points</span> for the second straight week. Zo managed 
                to make a game of it, despite a bunch of his top players sidelined on 
                bye. Meanwhile, Big D’s team <span className="bold orange">v</span>, <span className="bold red">firing on all cylinders</span> offensively and defensively with 
                <span className="bold green"> no apparent weaknesses</span>. While Zo fought valiantly, The Big D simply would not be denied!!!!!
                </CardText>          
                </CardBody>
            </Card>     
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">! Zo</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">The Big D</CardTitle>          
                </CardBody>
            </Card> 
          </Row>
          <br/>
            <br/>
          <Row>       
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">LET ME SEE YOUR TDs</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">Dandy MEN</CardTitle>          
                </CardBody>
            </Card>        
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>         
                <span className="bold team-name-tag">Dandy MEN</span> knocked off the <span className="bold green">reigning champ</span>, <span className="bold team-name-tag">LET ME SEE YOUR TDs</span>, in 
                a <span className="bold red">stunning upset</span>, showing that the king’s reign may be coming 
                to an end. Dandy's <span className="bold oranglethal combinatione"></span> of <span className="italic bold">Lamar Jackson, Jordan Love, and Roquan Smith</span> proved 
                too much to handle, delivering across all phases and looking like a <span className="bold orange">tough squad</span> to compete 
                with. TDs had chances to pull off a comeback, but Patrick Mahomes and Alvin Kamara couldn’t come 
                through <span className="bold blue">when it mattered most</span>, <span className="bold red">sputtering</span> down the stretch. In a <span className="bold green">battle of powerhouses</span>, Dandy MEN emerged victorious, 
                signaling that a new contender is ready to take the throne!!!!!
                
                {/* <img
                  alt="..."
                  src={require("assets/img/broke-make-it-rain.gif").default}
                ></img> */}
              
                </CardText>          
                </CardBody>
            </Card>               
          </Row>
          <br/>
          <br/>
          <Row>
          <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>       
                <span className="bold team-name-tag">TOO Easy</span> continued his <span className="bold green">remarkable turnaround</span>, 
                defeating <span className="bold team-name-tag">Invisible Juice</span> in a game that highlighted 
                both <span className="bold blue">momentum swings</span> and <span className="bold red">misfortune</span>. <span className="italic bold">Juice</span>, battling shorthanded 
                with <span className="italic bold">Anthony Richardson</span> sidelined due to injury, dropped his <span className="bold red">second straight</span> 
                after a <span className="bold orange">hot start</span> to the season. Meanwhile, <span className="italic bold">Easy</span> is <span className="bold green">on fire</span>, turning an 0-2 start 
                into a t<span className="bold blue">hree-game winning streak</span>. Powered by the brilliance of Caleb Williams, Easy seems 
                destined for bigger things and might just ride his star QB all the way to the <span className="bold blue">promised land</span>. It’s 
                been a tale of two seasons for these teams, with <span className="bold green">Easy now surging</span> and Juice <span className="bold red">searching for answers</span>!!!!!
                </CardText>          
                </CardBody>
            </Card>                  
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Invisible Juice</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">TOO Easy</CardTitle>          
                </CardBody>
            </Card>
          </Row>
          <br/>
          <br/>
          <Row>            
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Bama BlackOut</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">The Goonz</CardTitle>
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>  
                In a unusualy low scoring output for the commish vs commish battle, 
                <span className="bold team-name-tag">The Goonz</span> took down <span className="bold team-name-tag">Bama BlackOut</span> with a <span className="bold green">commanding performance</span>, riding the steady hand 
                of <span className="italic bold">Jayden Daniels</span>. <span className="italic bold">The Goonz</span> showed off <span className="bold blue">consistent production</span> across the board, with every 
                position pulling its weight, making them a <span className="bold orange">true fantasy juggernaut</span> this week. Meanwhile, <span className="italic bold">Bama Blackout</span> continued his 
                unfortunate slide, dropping his <span className="bold red">third consecutive matchup</span> in a stunning <span className="bold red">fall from grace</span>. Once the top dog, Bama’s team 
                seems to be in free fall, searching for answers as their once-dominant season teeters on the edge. The Goonz, however, are starting to <span className="bold green">round into midseason form</span>!!!!!
                </CardText>          
                </CardBody>
            </Card>      
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Matchups;
