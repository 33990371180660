import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function WeekSummary() {
  return (
    <>
    {/* <span className="italic bold">dominant</span> */}
    {/* <span className="bold">dominant</span> */}
      <div className="section section-weekly-summary">
        <Container>
          <Row>
            <Col md="12">
            <p className="category">

              {/* <YoutubeEmbed embedId="yiFNrO33bSo"></YoutubeEmbed><br></br> */}
              <span className="bold">Week 5</span>> of the NFL season was highlighted by a mix 
              of <span className="bold green">seasoned veterans</span> and <span className="bold blue">rising stars</span>, with <span className="italic bold">Kirk Cousins</span> putting 
              up a legendary performance, throwing for 500+ yards 
              and 4 touchdowns. <span className="italic bold">Ja'Marr Chase</span> was unstoppable, catching 10 passes for 193 yards and 2 touchdowns, reminding 
              everyone why he’s one of the best in the league. Meanwhile, <span className="italic bold">Caleb Williams</span>, finally making that <span className="bold orange">number one overall pick</span> worth it, lighting up the 
              field with <span className="bold">300 yards and 2 touchdowns</span>, displaying poise and playmaking ability you'd expect out of a number one pick. <span className="italic bold">Jayden Daniels</span> still doing Jayden Daniels things whooping
              up on Cleveland. Week 5 was a <span className="bold green">showcase of fantasy firepower</span>! Let’s take a look at the <span className="bold red">week 5 recap</span>!

            {/* Congrats on what's gotta be the most <span className="bold green">entertaining</span>, <span className="bold blue">competitive</span> season in the history of the <span className="bold">League</span>. To the teams that didn't make the playoffs, we thank you for your <span className="bold orange">grit and determination</span>. <br/><br/>
            <div className="text-center"> On the AFC's side, we got two teams:</div>
            <br/>
            <span className="bold">BAMA BLACKOUT:</span> He's been leading the pack all year after starting out 8-0. Can he break through for the first time in 6 years?<br/><br/> */}
            
            </p>
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-1.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/devin-white.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WeekSummary;
